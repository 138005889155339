import React from "react"
import { Table, message, Tag } from "antd"

import { getActiveCodeOrderList } from "../../api/order"
import Layout from "../../layouts"

import "./active-code.css"

class ActiveCodeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
    }
  }

  componentWillMount() {
    this.initOrderList()
  }

  initOrderList = async () => {
    const res = await getActiveCodeOrderList()
    const data = res.data.data
    if (data) {
      this.setState({ data })
      // const { themeList, pageNum } = data
      // this.setState({ themes: themeList, loading: false, total: pageNum, currentPage })
    } else {
      message.error("加载激活码列表出错")
    }
  }

  render() {
    const { data } = this.state

    const columns = [
      {
        title: "激活码",
        dataIndex: "activeCode",
        key: "activeCode",
      },
      {
        title: "激活码过期时间",
        dataIndex: "endTime",
        key: "endTime",
        render: endTime => {
          let element = null
          if (new Date().getTime() > new Date(endTime).getTime()) {
            element = <Tag color="red">已过期</Tag>
          }
          return (
            <>
              {element}
              {endTime && endTime.slice(0, -9).replace("T", " ")}
            </>
          )
        },
      },
      {
        title: "有效期",
        dataIndex: "duration",
        key: "duration",
        render: duration => duration + "天",
      },
      {
        title: "可用设备",
        dataIndex: "deviceNum",
        key: "deviceNum",
        render: deviceNum => deviceNum + "台",
      },
      {
        title: "已绑定设备",
        dataIndex: "deviceInfoList",
        key: "deviceInfoList",
        render: deviceInfoList =>
          deviceInfoList && deviceInfoList.map(item => <p>{item}</p>),
      },
      {
        title: "订单ID",
        dataIndex: "orderId",
        key: "orderId",
      },
    ]
    return (
      <Layout isSpacerDisplay={true} title="Hello 部落">
        <div className="mdnice-active-code-container">
          <div className="mdnice-active-code-table-container">
            <Table bordered columns={columns} dataSource={data} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default ActiveCodeList
